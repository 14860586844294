<template lang="pug">
  .filters
    .row.m-b-10
      .col-md-6(:class="{ 'col-lg-3': isProduction, 'col-lg-4': !isProduction }")
        .filter-title {{ $t("price_calendar.filters.ota") }}
        AppDropdown.ota-list(
          close-on-select
          :value="filters.ota"
          :items="otas"
          @select="$emit('change-ota', $event)"
        )
      .col-md-6(:class="{ 'col-lg-3': isProduction, 'col-lg-4': !isProduction }")
        .filter-title {{ $t("price_calendar.filters.shop") }}
        AppDropdown.shop-name(
          disabled
          v-b-tooltip.hover
          v-if="isEmpty(filters.ota)"
          :placeholder="$t('inventory_management.select_shop')"
          :title="$t('price_calendar.filters.select_ota_first')"
        )
        AppDropdown.shop-name(
          v-else
          close-on-select
          searchable
          :value="filters.shop"
          :items="shops"
          :loading="shopsLoading"
          @select="$emit('change-shop', $event)"
        )
      .col-md-6(:class="{ 'col-lg-3': isProduction, 'col-lg-4': !isProduction }")
        .filter-title {{ $t("price_calendar.filters.car_class") }}
        AppDropdown.car-class-name(
          disabled
          v-b-tooltip.hover
          v-if="isEmpty(filters.shop) || isEmpty(filters.ota)"
          :placeholder="$t('price_calendar.filters.select_car_classes')"
          :title="$t('price_calendar.filters.select_shop_first')"
        )
        AppDropdown.car-class-name(
          v-else
          searchable
          close-on-select
          :value="filters.carClass"
          :items="carClasses"
          :loading="carClassesLoading"
          @select="$emit('change-car-class', $event)"
        )
      .col-md-6.col-lg-3(v-if="isProduction")
        .toggler-item.w-100
          .filter-title {{ $t("price_calendar.filters.insurance") }}
          Toggler.m-t-12(
            :value="filters.insurance"
            :items="insuranceList"
            @change="$emit('change-insurance', $event)"
          )
    .row.m-b-15(v-if="isProduction")
      .col-md-12.col-lg-9
        .filter-title {{ $t("price_calendar.filters.departure_date") }}
        .d-flex
          AppDatepicker(
            :value="departureDate"
            :range="true"
            :clearable="false"
            :disabled-date="disabledDate"
            @change="$emit('change-departure-date', $event)"
          )
          AppButton.today.short(
            name="today"
            title="inventory_management.today"
            @click="setToday"
          )
    .row.m-b-15(v-else)
      .col-md-6.col-lg-4.pr-4
        .d-flex.justify-content-between
          .toggler-item.w-100.pr-1
            .filter-title {{ $t("price_calendar.filters.insurance") }}
            Toggler(
              :value="filters.insurance"
              :items="insuranceList"
              @change="$emit('change-insurance', $event)"
            )
          .toggler-item.w-100.pl-1
            .filter-title {{ $t("price_calendar.filters.time_slot") }}
            Toggler(
              :value="filters.time"
              :items="timeList"
              @change="$emit('change-time', $event)"
            )
      .col-md-6.col-lg-4.position-relative.mr-5.datepicker_container
        .filter-title {{ $t("price_calendar.filters.departure_date") }}
        AppDatepicker(
          :value="departureDate"
          :range="true"
          :clearable="false"
          :disabled-date="disabledDate"
          @change="$emit('change-departure-date', $event)"
        )
        AppButton.today.short.today_positioned(
          name="today"
          title="inventory_management.today"
          @click="setToday"
        )
</template>

<script>
  import { isEmpty } from "lodash-es"
  import { startOfCurrentDate } from "@/helpers/price-calendar"
  import { add as dateAdd, startOfDay, endOfDay } from "date-fns"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppButton: () => import("@/components/elements/AppButton"),
      Toggler: () => import("@/pages/PriceManagement/PriceCalendar/Filters/Toggler")
    },

    props: {
      currentPlan: Object,
      filters: Object,
      otas: Array,
      shops: Array,
      carClasses: Array,
      insuranceList: Array,
      timeList: Array,
      shopsLoading: Boolean,
      carClassesLoading: Boolean,
      departureDate: Array
    },

    data() {
      return {
        // temporarily hide hourly functionality for prod, after testing remove all connected to Production environment
        isProduction: process.env.VUE_APP_ENV === "production"
      }
    },

    computed: {
      minDate({ currentPlan }) {
        return new Date(currentPlan.open_start_date)
      },

      maxDate({ currentPlan }) {
        return new Date(currentPlan.open_end_date)
      }
    },

    methods: {
      isEmpty,

      disabledDate(date) {
        return date < startOfDay(this.minDate) || date > endOfDay(this.maxDate)
      },

      setToday() {
        const startDate = startOfCurrentDate < this.minDate ? this.minDate : startOfCurrentDate
        const afterMonthDate = dateAdd(startOfCurrentDate, { months: 1 })
        const endDate = afterMonthDate > this.maxDate ? this.maxDate : afterMonthDate

        this.$emit("change-departure-date", [startDate, endDate])
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  $filter-height: 33px

  .filters
    +filter-title

    ::v-deep
      .app-select
        margin-left: -6px

      .app-button
        height: $filter-height

      .datepicker
        input
          height: $filter-height
      .datepicker_container
        .datepicker
          width: 100%

    .today
      border: 1px solid $default-purple
      color: $default-purple
      height: $filter-height
      text-transform: uppercase

      &_positioned
        bottom: 0
        right: -45px
        position: absolute
</style>
